import React, { useState, useEffect, useContext } from 'react'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'
import { Box } from '@material-ui/core'
import { Text, TextSm, Img } from '@system'
import PageContext from '@context'
import get from 'lodash/get'
import {
  getFormattedDateTime,
  getSortedStories,
  getCurrentDateAndTimeInUTC,
} from '@helpers'
import { Link } from 'gatsby'
import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

dayjs.extend(utc)
dayjs.extend(isSameOrBefore)

const useStyles = makeStyles((theme) => ({
  displayCards: {
    display: 'flex',
    flexDirection: 'column',
    padding: '25px 8px',
    textAlign: (props) =>
      props.alignment === 'left' ? '' : `${props.alignment}`,
    width: '33.33333333%',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  title: {
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: '10px',
    display: 'block',
    fontFamily: 'Artifakt Element',
    fontSize: '14px',
    lineHeight: '16px',
  },
  formattedDate: {
    color: theme.palette.common.white,
    [theme.breakpoints.up('sm')]: {},
    display: 'block',
  },
  cardContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    margin: 'auto',
    flex: 1,
    maxWidth: '100%',
  },
  resourceCard: {
    height: '300px',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    minHeight: '250px',
    width: '100%',
  },
  resourceCardDetails: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    height: '110px',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
    bottom: '0px',
    padding: '20px 15px',
    opacity: 0.9,
  },
  eventImage: {
    width: '100%',
    display: 'flex',
    margin: 'auto',
    height: '300px',
    '& img': {
      objectFit: 'cover',
    },
  },
}))

const ResourceReferences = (props) => {
  const classes = useStyles(props.blok)
  const pageContext = useContext(PageContext)
  const isEventsModule = true

  const {
    sortBy,
    filters,
    isReferencingAllPublishedEvents,
    numberOfReferences,
    events,
    type,
  } = props.blok

  const broadensResults = 'broadensResults'
  const narrowsResults = 'narrowsResults'

  const alphabetical = 'alphabetical'
  const chronological = 'chronological'
  const reverseChronological = 'reverse-chronological'

  const types = 'type'
  const filteredBroadensResults = []
  const filteredNarrowsResults = []
  const virtualEvent = ' - Virtual Event'
  const isFeaturedEventsFilter =
    get(filters, '[0].filterDataPath') === 'isFeatured'

  const currentDateAndTimeInUTC = getCurrentDateAndTimeInUTC()

  const getFilteredResults = (filterLogicRules, logicType) =>
    filterLogicRules
      ? filterLogicRules.filter(
          (filter) => filter.filterResultLogic === logicType
        )
      : filters.filter((filter) => {
          const hasFilterOptions = get(filter.options, '[0].fieldOptions')

          return (
            filter.filterResultsLogic === logicType &&
            (filter.component === 'booleanFilter' ||
              (hasFilterOptions && hasFilterOptions.length > 0))
          )
        })

  const filterNarrowsResults = getFilteredResults(
    props.filterLogicRules,
    narrowsResults
  )

  const filterBroadensResults = getFilteredResults(
    props.filterLogicRules,
    broadensResults
  )

  const handleEvents = isReferencingAllPublishedEvents
    ? pageContext.events
        .filter(
          (event) =>
            dayjs(currentDateAndTimeInUTC).isSameOrBefore(event.content.endDate) // endDate is in UTC format
        )
        .map((event) => event.content)
        .reverse()
    : events &&
      events.map((event) => ({ ...event, ...event.content })).reverse()

  const getLink = (story) =>
    get(
      pageContext.events.filter((entry) => entry.content.title === story.title),
      '[0].full_slug'
    )

  const fetchEventsFilterOptions = (filterData) => {
    const eventsFilterData = filters.filter(
      (filter) => filter.filterDataPath === filterData
    )
    const filtersFieldOption = get(
      eventsFilterData,
      '[0].options.[0].fieldOptions'
    )

    return eventsFilterData.length > 0 ? filtersFieldOption : []
  }

  const getFormattedFilterOpions = (filtersName) =>
    filters
      ? fetchEventsFilterOptions(filtersName)
      : filtersName === ''
      ? []
      : typeof filtersName === 'string'
      ? [filtersName]
      : filtersName

  const eventsType = getFormattedFilterOpions(types)

  const isSortedEvents =
    sortBy === alphabetical ||
    sortBy === chronological ||
    sortBy === reverseChronological

  let allMatched = false
  let matchedEvents = false
  let pushEvents = false

  const fetchMultipleValueLogic = (value) => {
    const multiValueLogic = filters
      ? filters.some(
          (multiValueLogic) =>
            multiValueLogic.filterDataPath === value &&
            multiValueLogic.multipleValuesLogic === narrowsResults
        )
      : props.filterLogicRules.some(
          (multiValueLogic) =>
            multiValueLogic.filterDataPath === value &&
            multiValueLogic.multiValueLogic === narrowsResults
        )

    return multiValueLogic ? narrowsResults : broadensResults
  }

  const fetchListOfMatchedEvents = (
    filterOptions,
    eventOptions,
    filteredBroadensResults,
    multipleValuesLogic,
    event
  ) => {
    return multipleValuesLogic === narrowsResults
      ? filterOptions.forEach((filterOption, index) =>
          eventOptions &&
          eventOptions.length > 0 &&
          eventOptions.indexOf(filterOption) !== -1
            ? filterOptions.length - 1 === index &&
              !allMatched &&
              !pushEvents &&
              event
              ? (filteredBroadensResults.push(event), (allMatched = true))
              : (matchedEvents = true)
            : (pushEvents = true)
        )
      : filterOptions.forEach((filterOption) =>
          eventOptions &&
          eventOptions.length > 0 &&
          eventOptions.indexOf(filterOption) > -1 &&
          !matchedEvents &&
          event
            ? (filteredBroadensResults.push(event), (matchedEvents = true))
            : (matchedEvents = true)
        )
  }
  const [filteredEvents, setFilteredEvents] = useState([])

  useEffect(() => {
    loadFilteredEvents(handleEvents)
  }, [type, pageContext.events])

  const filterMatchedEvents = (
    filterResult,
    category,
    resourceCategory,
    resourceOption,
    filteredArray,
    story
  ) => {
    const hasMatched = hasFilterOptionMatched(filterResult, category)
    if (hasMatched) {
      const multipleValues = fetchMultipleValueLogic(category)
      fetchListOfMatchedEvents(
        resourceCategory,
        resourceOption,
        filteredArray,
        multipleValues,
        story
      )
    }
  }

  const hasFilterOptionMatched = (filterResults, option) =>
    filterResults.some((filterResult) => filterResult.filterDataPath === option)

  const fetchBroadensResult = (handleEvents) => {
    handleEvents.forEach((event) => {
      if (eventsType && eventsType.length > 0) {
        filterMatchedEvents(
          filterBroadensResults,
          types,
          eventsType,
          event.type,
          filteredBroadensResults,
          event
        )
        pushEvents = false
      }
      if (!!isFeaturedEventsFilter) {
        event.isFeatured &&
          (filteredBroadensResults.push(event), (allMatched = true))
      }

      matchedEvents = false
      allMatched = false
    })
  }

  const fetchNarrowsResult = (filterNarrowsEvents) => {
    filterNarrowsEvents.forEach((event) => {
      if (eventsType && eventsType.length > 0) {
        filterMatchedEvents(
          filterNarrowsResults,
          types,
          eventsType,
          event.type,
          [],
          filteredNarrowsResults
        )
      }
      if (!!isFeaturedEventsFilter) {
        event.isFeatured && (matchedEvents = true)
      }

      matchedEvents && filteredNarrowsResults.push(event)
      matchedEvents = false
    })
  }

  const loadFilteredEvents = (handleEvents) => {
    if (filters && filters.length > 0) {
      filterBroadensResults &&
        filterBroadensResults.length > 0 &&
        fetchBroadensResult(handleEvents)

      const filterNarrowsEvents =
        filterBroadensResults && filterBroadensResults.length > 0
          ? filteredBroadensResults
          : handleEvents

      filterNarrowsResults &&
        filterNarrowsResults.length > 0 &&
        filterNarrowsEvents &&
        fetchNarrowsResult(filterNarrowsEvents)
    }

    const getSortedEvents = () => {
      if (filterNarrowsResults.length > 0 || filterBroadensResults.length > 0) {
        if (filterNarrowsResults.length > 0) {
          return filteredNarrowsResults.length > 0 && isSortedEvents
            ? getSortedStories(
                filteredNarrowsResults,
                sortBy,
                pageContext.events,
                isEventsModule
              )
            : filteredNarrowsResults
        } else {
          return isSortedEvents
            ? getSortedStories(
                filteredBroadensResults,
                sortBy,
                pageContext.events,
                isEventsModule
              )
            : filteredBroadensResults
        }
      } else {
        return getSortedStories(
          handleEvents,
          sortBy,
          pageContext.events,
          isEventsModule
        )
      }
    }

    setFilteredEvents(
      sortBy === reverseChronological || sortBy === 'none'
        ? getSortedEvents().reverse()
        : getSortedEvents()
    )
  }

  const autodeskHostedEventStories =
    pageContext.events &&
    pageContext.events
      .filter((event) => event.content.type === 'Autodesk Hosted')
      .map((event) => event.content)

  const allFilteredEventsList =
    filteredEvents && filteredEvents.length >= numberOfReferences
      ? filteredEvents
      : [...filteredEvents, ...autodeskHostedEventStories]

  const EventsFeaturedCard = (props) => {
    const {
      title,
      image,
      isVirtualEvent,
      startDate,
      endDate,
      defaultFeaturedImage,
    } = props.blok

    const featuredEventImage = get(image, '[0].image')

    const getEventsDateAndTime = (startDate, endDate) => {
      const yearOfStartDate = getFormattedDateTime(startDate, 'YYYY')
      const yearOfEndDate = getFormattedDateTime(endDate, 'YYYY')
      const monthOfStartDate = getFormattedDateTime(startDate, 'MMMM')
      const monthOfEndDate = getFormattedDateTime(endDate, 'MMMM')

      if (dayjs(startDate).isSame(endDate)) {
        return `${getFormattedDateTime(
          startDate,
          'MMMM DD'
        )}, ${yearOfStartDate}`
      } else if (yearOfStartDate === yearOfEndDate) {
        return monthOfStartDate === monthOfEndDate
          ? `${getFormattedDateTime(
              startDate,
              'MMMM DD'
            )} - ${getFormattedDateTime(endDate, 'DD')}, ${getFormattedDateTime(
              startDate,
              'YYYY'
            )}`
          : `${getFormattedDateTime(
              startDate,
              'MMM DD'
            )} - ${getFormattedDateTime(endDate, 'MMM DD')}, ${yearOfStartDate}`
      } else {
        return `${getFormattedDateTime(
          startDate,
          'MMM DD'
        )} - ${getFormattedDateTime(endDate, 'MMM DD')}`
      }
    }

    return (
      <Box container="true" className={classes.resourceCard}>
        <Img
          src={featuredEventImage || defaultFeaturedImage}
          className={classes.eventImage}
          alt="ACC featured event image"
        />
        <Box className={classes.resourceCardDetails}>
          <Text className={classes.title}>{title}</Text>
          <TextSm className={classes.formattedDate}>
            {getEventsDateAndTime(startDate, endDate)}
            {!!isVirtualEvent && virtualEvent}
          </TextSm>
        </Box>
      </Box>
    )
  }

  const getSlug = (story) =>
    get(
      pageContext.events.filter((entry) => entry.content.title === story.title),
      '[0].slug'
    )

  const defaultFeaturedImages = [
    `https://a.storyblok.com/f/64835/4320x1800/cf6820831c/1920x600-acc-events-3x-1.jpg`,
    'https://a.storyblok.com/f/64835/4826x2456/a544f1fcdb/vegas-arena-team.jpg',
    'https://a.storyblok.com/f/64835/4032x3024/230dfc85df/mortenson_co-location.jpeg',
  ]

  const surfaceRegionalStories = (a, b) => {
    return a.isLocalizedStory ? -1 : 1
  }

  return (
    <SbEditable content={props.blok}>
      <Box className={classes.cardContainer} id="event-references">
        {allFilteredEventsList &&
          allFilteredEventsList
            .sort(surfaceRegionalStories)
            .slice(0, numberOfReferences)
            .map((event, index) => {
              const eventsBlokData = {
                alignment: 'left',
                image:
                  !!event.image && event.image.length > 0 ? event.image : [],
                noDescMargin: false,
                notVisibleOnMobile: false,
                title: event.title,
                resourceReference: true,
                link: getLink(event),
                startDate: event.startDate,
                endDate: event.endDate,
                isVirtualEvent: event.isVirtualEvent,
                defaultFeaturedImage: defaultFeaturedImages[index],
              }
              return (
                <Box
                  className={classNames(classes.displayCards)}
                  key={event._uid}
                >
                  <Link to={getSlug(event)}>
                    <EventsFeaturedCard blok={eventsBlokData} />
                  </Link>
                </Box>
              )
            })}
      </Box>
    </SbEditable>
  )
}

export default ResourceReferences
